import React from "react";
import "./DirectionPages.css";
import austriaMain from "../../images/austria-cover.webp";
import austriaMainMob from "../../images/austria-cover-mob.webp";
import hotOne from "../../images/Grand-Hotel-Zell-Am-See.webp";
import hotTwo from "../../images/Boutique-Hotel-LiebesNesterl-Bergwirt.webp";
import hotThree from "../../images/St-Georg.webp";

import pin from "../../images/pin.svg";
import stars from "../../images/4-stars.svg";
import checkIn from "../../images/check-in.svg";
import checkOut from "../../images/check-out.svg";
import arrow from "../../images/arrow.svg";

const Austria = () => {
  return (
    <main className="direction-page">
      <section className="cover">
        <img
          className="cover__img pc"
          src={austriaMain}
          alt="main cover"
          width="1440"
          height="600"
        />
        <img
          className="cover__img mob"
          src={austriaMainMob}
          alt="main cover"
          width="375"
          height="560"
        />
        <div className="cover__overlay">
          <h1 className="col-white cover__header">Austria</h1>
          <p className="h3-size col-white cover__text">
            Wiedeń to miasto muzyki i sztuki, znane ze wspaniałych pałaców i sal
            koncertowych.
          </p>
        </div>
      </section>
      <section className="hotels direction__pages">
        <h2 className="hotels__header">Nasze najlepsze rekomendacje hoteli</h2>
        <p className="t-24 col-grey hotels__text">
          Austria to kraj majestatycznych gór, wspaniałych pałaców i bogatego
          dziedzictwa muzycznego. Nasze biuro podróży wybrało najlepsze hotele,
          w których można cieszyć się prawdziwą austriacką gościnnością i
          komfortem. Od luksusowych hoteli w Wiedniu po przytulne domki w
          Alpach, mamy idealne miejsce na niezapomniane wakacje.
        </p>
        <ul className="hotels__container">
          <li className="hotel hotel-one">
            <div className="hotel__text-container">
              <p className="h3-size hotel__name">Grand Hotel Zell Am See</p>
              <div className="hotel__rating-container">
                <img
                  src={stars}
                  alt="star icon"
                  title="star icon"
                  className="hotel__star"
                />
                <p className="t-16 col-grey rating">402 recenzji</p>
              </div>
              <div className="hotel__adress-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="24"
                  height="24"
                />
                <p className="t-16 col-grey">
                  Esplanade 4-6, Zell am See, Salzburg, 5700
                </p>
              </div>
              <p className="hotel__about t-16 col-grey">
                Grand Hotel Zell Am See znajduje się w pobliżu jeziora Zell i
                oferuje kasyno, taras oraz sklepy na miejscu. Dla tych, którzy
                chcą spróbować szczęścia, hotel oferuje automat do gry. Hotelowe
                spa GrandSpa oferuje manicure/pedicure, masaże i zabiegi na
                ciało. Goście mogą zjeść posiłek w dwóch restauracjach na
                terenie obiektu.
              </p>
              <p className="hotel__lang t-16">Języki:</p>
              <p className="t-16 col-grey">English, German, Italian</p>
              <div className="hotel__check">
                <div className="hotel__check-in">
                  <img
                    src={checkIn}
                    alt="check-in icon"
                    title="check-in icon"
                    width="30"
                    height="30"
                  />
                  <p className="t-16">Czas zameldowania</p>
                  <p className="t-16 col-grey">3:00 PM</p>
                </div>
                <div className="hotel__check-out">
                  <img
                    src={checkOut}
                    alt="check-out icon"
                    title="check-out icon"
                    width="30"
                    height="30"
                  />
                  <p className="t-16">Czas wymeldowania</p>
                  <p className="t-16 col-grey">10:30 PM</p>
                </div>
              </div>
              <a
                className="hotel__reserve t-16"
                target="_blank"
                rel="noreferrer"
                href="https://www.expedia.com/Zell-Am-See-Hotels-Grand-Hotel-Zell-Am-See.h1738601.Hotel-Information?chkin=2024-07-14&chkout=2024-07-16&x_pwa=1&rfrr=HSR&pwa_ts=1720945427352&referrerUrl=aHR0cHM6Ly93d3cuZXhwZWRpYS5jb20vSG90ZWwtU2VhcmNo&useRewards=false&rm1=a2&regionId=11&destination=Austria&destType=MARKET&latLong=47.516232%2C14.550072&amenities=CASINO&sort=RECOMMENDED&top_dp=389&top_cur=USD&userIntent=&selectedRoomType=212711777&selectedRatePlan=238201772&searchId=8163e9ae-a383-4216-9ac6-a1ae6957c7d6&propertyName=Grand+Hotel+Zell+Am+See"
              >
                Rezerwacja hotelu
                <img
                  src={arrow}
                  alt="arrow icon"
                  title="arrow icon"
                  className="hotel__arrow"
                />
              </a>
            </div>
            <div className="hotel__img-container">
              <img
                src={hotOne}
                alt="Grand Hotel Zell Am See"
                title="Grand Hotel Zell Am See"
                width="417"
                height="490"
                className="hotel__img"
              />
            </div>
          </li>

          <li className="hotel hotel-two">
            <div className="hotel__text-container">
              <p className="h3-size hotel__name">
                Boutique-Hotel LiebesNesterl Bergwirt
              </p>
              <div className="hotel__rating-container">
                <img
                  src={stars}
                  alt="star icon"
                  title="star icon"
                  className="hotel__star"
                />
                <p className="t-16 col-grey rating">11 recenzji</p>
              </div>
              <div className="hotel__adress-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="24"
                  height="24"
                />
                <p className="t-16 col-grey">
                  Graden 127, Koeflach, Styria, 8593
                </p>
              </div>
              <p className="hotel__about t-16 col-grey">
                Położony w pobliżu Glasmuseum Barnbach, Boutique-Hotel
                LiebesNesterl Bergwirt oferuje 18 dołków do golfa, kasyno i
                taras. Hotel oferuje takie udogodnienia jak bukmacher, 30
                automatów do gry i 20 stołów do gry w kasynie. Aby odpocząć i
                zrelaksować się, odwiedź 2 gorące źródła. Hotelowa restauracja
                oferuje śniadania, kolacje i lekkie posiłki.
              </p>
              <p className="hotel__lang t-16">Języki:</p>
              <p className="t-16 col-grey">English, German</p>
              <div className="hotel__check">
                <div className="hotel__check-in">
                  <img
                    src={checkIn}
                    alt="check-in icon"
                    title="check-in icon"
                    width="30"
                    height="30"
                  />
                  <p className="t-16">Czas zameldowania</p>
                  <p className="t-16 col-grey">3:00 PM</p>
                </div>
                <div className="hotel__check-out">
                  <img
                    src={checkOut}
                    alt="check-out icon"
                    title="check-out icon"
                    width="30"
                    height="30"
                  />
                  <p className="t-16">Czas wymeldowania</p>
                  <p className="t-16 col-grey">11:00 PM</p>
                </div>
              </div>
              <a
                className="hotel__reserve t-16"
                target="_blank"
                rel="noreferrer"
                href="https://www.expedia.com/Koeflach-Hotels-Boutique-Hotel-LiebesNesterl-Bergwirt.h23237428.Hotel-Information?chkin=2024-07-14&chkout=2024-07-16&x_pwa=1&rfrr=HSR&pwa_ts=1720945429727&referrerUrl=aHR0cHM6Ly93d3cuZXhwZWRpYS5jb20vSG90ZWwtU2VhcmNo&useRewards=false&rm1=a2&regionId=11&destination=Austria&destType=MARKET&latLong=47.516232%2C14.550072&amenities=CASINO&sort=RECOMMENDED&top_dp=260&top_cur=USD&userIntent=&selectedRoomType=221405548&selectedRatePlan=394868843&searchId=8163e9ae-a383-4216-9ac6-a1ae6957c7d6&propertyName=Boutique-Hotel+LiebesNesterl+Bergwirt"
              >
                Rezerwacja hotelu
                <img
                  src={arrow}
                  alt="arrow icon"
                  title="arrow icon"
                  className="hotel__arrow"
                />
              </a>
            </div>
            <div className="hotel__img-container">
              <img
                src={hotTwo}
                alt="Boutique-Hotel LiebesNesterl Bergwirt"
                title="Boutique-Hotel LiebesNesterl Bergwirt"
                width="417"
                height="490"
                className="hotel__img"
              />
            </div>
          </li>

          <li className="hotel hotel-three">
            <div className="hotel__text-container">
              <p className="h3-size hotel__name">St Georg</p>
              <div className="hotel__rating-container">
                <img
                  src={stars}
                  alt="star icon"
                  title="star icon"
                  className="hotel__star"
                />
                <p className="t-16 col-grey rating">60 recenzji</p>
              </div>
              <div className="hotel__adress-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="24"
                  height="24"
                />
                <p className="t-16 col-grey">
                  Schillerstraße 32, Zell am See, Salzburg, 5700
                </p>
              </div>
              <p className="hotel__about t-16 col-grey">
                Hotel St Georg położony jest w pobliżu ośrodka narciarskiego
                Schmittenhöhe i kolejki linowej AreitXpress i oferuje 18 dołków
                golfa, kasyno oraz taras. Ten hotel oferuje dostęp do stoków
                narciarskich, narciarstwo zjazdowe i narciarstwo biegowe. Na
                miejscu Goście mogą skorzystać z masażu w spa. Hotelowa
                restauracja zaprasza na kolację.
              </p>
              <p className="hotel__lang t-16">Języki:</p>
              <p className="t-16 col-grey">English, French, German, Italian</p>
              <div className="hotel__check">
                <div className="hotel__check-in">
                  <img
                    src={checkIn}
                    alt="check-in icon"
                    title="check-in icon"
                    width="30"
                    height="30"
                  />
                  <p className="t-16">Czas zameldowania</p>
                  <p className="t-16 col-grey">2:00 PM</p>
                </div>
                <div className="hotel__check-out">
                  <img
                    src={checkOut}
                    alt="check-out icon"
                    title="check-out icon"
                    width="30"
                    height="30"
                  />
                  <p className="t-16">Czas wymeldowania</p>
                  <p className="t-16 col-grey">10:00 PM</p>
                </div>
              </div>
              <a
                className="hotel__reserve t-16"
                target="_blank"
                rel="noreferrer"
                href="https://www.expedia.com/Zell-Am-See-Hotels-St-Georg.h3536101.Hotel-Information?chkin=2024-07-14&chkout=2024-07-16&x_pwa=1&rfrr=HSR&pwa_ts=1720945427352&referrerUrl=aHR0cHM6Ly93d3cuZXhwZWRpYS5jb20vSG90ZWwtU2VhcmNo&useRewards=false&rm1=a2&regionId=11&destination=Austria&destType=MARKET&latLong=47.516232%2C14.550072&amenities=CASINO&sort=RECOMMENDED&top_dp=145&top_cur=USD&userIntent=&selectedRoomType=201999314&selectedRatePlan=292027665&searchId=8163e9ae-a383-4216-9ac6-a1ae6957c7d6&propertyName=St+Georg"
              >
                Rezerwacja hotelu
                <img
                  src={arrow}
                  alt="arrow icon"
                  title="arrow icon"
                  className="hotel__arrow"
                />
              </a>
            </div>
            <div className="hotel__img-container">
              <img
                src={hotThree}
                alt="St Georg"
                title="St Georg"
                width="417"
                height="490"
                className="hotel__img"
              />
            </div>
          </li>
        </ul>
      </section>
    </main>
  );
};

export default Austria;
