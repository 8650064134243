import React from "react";
import "./Footer.css";
import age from "../../images/age.svg";
import telegram from "../../images/telegram.svg";
import inst from "../../images/instagram.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="contacts" className="footer">
      <div className="footer__el1">
        <p className="t-15 col-white">
          Jeśli chcesz się z nami skontaktować lub przesłać nam swoją opinię,
          zrób to. Możesz skontaktować się z nami poprzez
          <br></br>e-mail:
          <a href="mailto:grajowygrane@gmail.com" className="footer__email">
            grajowygrane@gmail.com
          </a>
        </p>
        <p className="footer__cookie-header col-white">Więcej informacji:</p>
        <p className="t-15 col-white">
          <span className="footer__span col-white"> grajowygrane.com</span>{" "}
          wykorzystuje pliki cookie, aby zapewnić najlepszą jakość. Odwiedzając
          stronę wyrażasz zgodę na używanie plików cookies.
        </p>
        <Link to="cookies" className="t-15">
          Polityca Cookie
        </Link>
      </div>
      <div className="footer__el2">
        <p className="t-15 col-white">
          Wszystkie informacje zawarte na naszej stronie służą wyłącznie celom
          informacyjnym.
        </p>
        <Link to="privacy" className="t-15">
          Polityka prywatności
        </Link>
      </div>
      <div className="footer__el3">
        <img
          src={age}
          alt="age limit 18+"
          title="age limit 18+"
          width="54"
          height="54"
        />
      </div>
      <div className="footer__el4">
        <a href="#">
          <img src={inst} alt="instagram icon" title="instagram icon" />
        </a>
        <a href="#">
          <img src={telegram} alt="telegram icon" title="telegram icon" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
