import React from "react";

const Cookies = () => {
  return (
    <main className="privacy margin-bot-block">
      <section>
        <h1 className="h3-size">Polityka Plików Cookie dla grajowygrane.com</h1>
        <p className="t-16">
          Jest to Polityka Plików Cookie dla grajowygrane.com, dostępna pod
          adresem grajowygrane.com
        </p>
        <h2 className="h3-size">Co to są pliki cookie</h2>
        <p className="t-16">
          Jak to jest powszechnie praktykowane na niemal wszystkich
          profesjonalnych stronach internetowych, ta strona używa plików cookie,
          które są małymi plikami pobieranymi na twój komputer, aby poprawić
          twoje doświadczenie. Ta strona opisuje, jakie informacje gromadzą, jak
          je wykorzystujemy i dlaczego czasami musimy przechowywać te pliki
          cookie. Udostępnimy również, jak możesz zapobiec przechowywaniu tych
          plików cookie, jednak może to obniżyć jakość działania strony lub
          "złamać" pewne elementy funkcjonalności strony.
        </p>
        <h2 className="h3-size">Jak używamy plików cookie</h2>
        <p className="t-16">
          Używamy plików cookie z różnych powodów opisanych poniżej. Niestety, w
          większości przypadków nie ma standardowych opcji przemysłowych do
          wyłączania plików cookie bez całkowitego wyłączania funkcjonalności i
          cech, które dodają do tej strony. Zaleca się, abyś zostawił włączone
          wszystkie pliki cookie, jeśli nie jesteś pewien, czy ich potrzebujesz,
          czy nie, na wypadek gdyby były używane do świadczenia usługi, z której
          korzystasz.
        </p>
        <h2 className="h3-size">Wyłączanie plików cookie</h2>
        <p className="t-16">
          Możesz zapobiec ustawianiu plików cookie poprzez dostosowanie ustawień
          w swojej przeglądarce (zobacz Pomoc swojej przeglądarki, aby
          dowiedzieć się, jak to zrobić). Pamiętaj, że wyłączenie plików cookie
          wpłynie na funkcjonalność tej i wielu innych stron internetowych,
          które odwiedzasz. Wyłączenie plików cookie zazwyczaj skutkuje również
          wyłączeniem pewnych funkcjonalności i cech tej strony. Dlatego zaleca
          się, abyś nie wyłączał plików cookie.
        </p>
        <h2 className="h3-size">Pliki cookie, które ustawiamy</h2>
        <p className="t-16">
          Pliki cookie używane do konfiguracji strony Aby zapewnić Ci dobre
          doświadczenie na tej stronie, oferujemy funkcjonalność ustawienia
          twoich preferencji dotyczących działania tej strony. Aby zapamiętać
          twoje preferencje, musimy ustawić pliki cookie, aby te informacje
          mogły być przywoływane za każdym razem, gdy korzystasz z strony,
          której dotyczy twoja preferencja.
        </p>
        <h2 className="h3-size">Pliki cookie stron trzecich</h2>
        <p className="t-16">
          W niektórych specjalnych przypadkach używamy również plików cookie
          dostarczanych przez zaufane strony trzecie. Poniższa sekcja
          szczegółowo opisuje, które pliki cookie stron trzecich możesz napotkać
          na tej stronie.
        </p>
        <p className="t-16">
          Ta strona używa Google Analytics, który jest jednym z najbardziej
          rozpowszechnionych i zaufanych rozwiązań analitycznych w sieci, aby
          pomóc nam zrozumieć, jak korzystasz ze strony i jak możemy poprawić
          twoje doświadczenie. Te pliki cookie mogą śledzić takie rzeczy, jak
          czas spędzony na stronie oraz strony, które odwiedzasz, abyśmy mogli
          nadal tworzyć angażujące treści.
        </p>
        <p className="t-16">
          Więcej informacji na temat plików cookie Google Analytics znajdziesz
          na oficjalnej stronie Google Analytics.
        </p>
        <h2 className="h3-size">Więcej informacji</h2>
        <p className="t-16">
          Mamy nadzieję, że to wyjaśniło ci sprawy i jak wcześniej wspomniano,
          jeśli jest coś, czego nie jesteś pewien, czy potrzebujesz, zazwyczaj
          bezpieczniej jest zostawić pliki cookie włączone na wypadek, gdyby
          wchodziły w interakcje z jedną z funkcji, z których korzystasz na
          naszej stronie.
        </p>
      </section>
    </main>
  );
};

export default Cookies;
