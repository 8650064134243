import React from "react";
import "./Directions.css";
import france from "../../images/direction-france.webp";
import holandia from "../../images/direction-holandia.webp";
import austria from "../../images/direction-austria.webp";
import { Link } from "react-router-dom";
import closeBtn from "../../images/close-btn-popup.svg";

const Directions = ({ isVisible, close }) => {

  return (
    <>
      {isVisible && (
        <main className="popup">
          <section className="directions">
            <img src={closeBtn} alt="close button" title="close button" className="directions__close" onClick={() => {close()}}/>
            <div className="directions__text">
              <h1 className="h2-size directions__header">Wybierz kraj</h1>
              <p className="directions__paragraph t-24 col-grey">
                Planujesz niezapomnianą podróż do Europy? Jesteśmy gotowi pomóc
                Ci w wyborze! Europa oferuje wiele różnych miejsc, z których
                każde jest wyjątkowe i pełne niesamowitych miejsc, kultury i
                historii. Dowiedz się więcej o najlepszych hotelach, atrakcjach
                i miejscach w tych wspaniałych miastach.
              </p>
            </div>

            <div className="directions__link-container">
              <Link to="/france" className="directions__link">
                <img
                  src={france}
                  alt="link to page about france"
                  title="link to page about france"
                  width="281"
                  height="189"
                  className="directions__img"
                />
              </Link>
              <Link to="/holandia" className="directions__link">
                <img
                  src={holandia}
                  alt="link to page about holandia"
                  title="link to page about holandia"
                  width="281"
                  height="189"
                  className="directions__img"
                />
              </Link>
              <Link to="/austria" className="directions__link">
                <img
                  src={austria}
                  alt="link to page about austria"
                  title="link to page about austria"
                  width="281"
                  height="189"
                  className="directions__img"
                />
              </Link>
            </div>
          </section>
        </main>
      )}
    </>
  );
};

export default Directions;
