import React from "react";
import "./Reviews.css";
import star from "../../images/star.svg";
import { useLocation } from "react-router-dom";

const Reviews = () => {

  const location = useLocation();
  return (
    <section className="reviews">
      {location.pathname === '/reviews' ? ( <h1 className="h2-size revuews__header">Opinie naszych klientów</h1>) : ( <h2 className="revuews__header">Opinie naszych klientów</h2>)}
      <p className="t-24">Co mówią o nas nasi podróżni</p>
      <div className="review__star-container">
        <div className="review__star star__big"></div>
        <div className="review__star star__big"></div>
        <div className="review__star star__big"></div>
        <div className="review__star star__big"></div>
        <div className="review__star star__big"></div>
        <p className="review__summ">4.9</p>
      </div>
      <ul className="reviews__container">
        <li className="reviews__item">
          <div className="review__top">
            <p className="review__quote">“</p>
            <div className="review__star"></div>
            <p className="t-24 review__rating">5.0</p>
          </div>
          <div className="review__bottom">
            <p className="t-16 review__text">
              To była niesamowita wycieczka! Zarezerwowałem wycieczkę do Paryża
              za pośrednictwem tego biura podróży i byłem bardzo zadowolony.
              Wszystko było zorganizowane na najwyższym poziomie: transfery,
              wycieczki, hotel. Szczególnie podobało mi się indywidualne
              podejście i dbałość o szczegóły.
            </p>
            <p className="t-24 review__name">Anna</p>
            <p className="t-16 col-grey">Warszawa</p>
          </div>
        </li>
        <li className="reviews__item">
          <div className="review__top">
            <p className="review__quote">“</p>
            <div className="review__star"></div>
            <p className="t-24 review__rating">5.0</p>
          </div>
          <div className="review__bottom">
            <p className="t-16 review__text">
              Dziękujemy za niesamowite wakacje w Amsterdamie! Świetna obsługa,
              szczegółowe rekomendacje dotyczące zabytków i restauracji. Dzięki
              Tobie mogliśmy zobaczyć wszystkie najważniejsze atrakcje i cieszyć
              się naszą podróżą.Chętnie skorzystam z waszych usług ponownie.
            </p>
            <p className="t-24 review__name">Marek</p>
            <p className="t-16 col-grey">Kraków</p>
          </div>
        </li>
        <li className="reviews__item">
          <div className="review__top">
            <p className="review__quote">“</p>
            <div className="review__star"></div>
            <p className="t-24 review__rating">5.0</p>
          </div>
          <div className="review__bottom">
            <p className="t-16 review__text">
              Biuro podróży pomogło nam zorganizować wycieczkę do Wiednia i była
              to najlepsza decyzja! Hotel był wspaniały, wszystkie wycieczki
              były na najwyższym poziomie. Specjalne podziękowania za pomoc w
              wyborze restauracji na specjalną kolację.
            </p>
            <p className="t-24 review__name">Katarzyna</p>
            <p className="t-16 col-grey">Gdańsk</p>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default Reviews;
