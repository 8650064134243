import { Route, Routes } from "react-router-dom";
import "../App/App.css";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Directions from "../Directions/Directions";
import HotDeals from "../HotDeals/HotDeals";
import Reviews from "../Reviews/Reviews";
import Footer from "../Footer/Footer";
import Privacy from "../Privacy/Privacy";
import Cookies from "../Cookies/Cookies";
import ScrollToTop from "../Scroll/Scroll";
import France from "../DirectionPages/France";
import Holandia from "../DirectionPages/Holandia";
import Austria from "../DirectionPages/Austria";
import { useState, useEffect } from "react";
import ContactUs from "../ContactUs/ContactUs";

function App() {
  const [isVisible, setIsVisible] = useState(false);

  function closePopup() {
    setIsVisible(false);
  }
  
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        closePopup();
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  function makePopupVisible() {
    setIsVisible(true);
  }

  return (
    <div className="page">
      <ScrollToTop />
      <Header open={makePopupVisible} />
      <Directions isVisible={isVisible} close={closePopup}/>
      <Routes>
        <Route
          path="/"
          element={<Main />}
        />
        <Route path="/directions" element={<Directions />} />
        <Route path="/hot-deals" element={<HotDeals />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/contact-us" element={<ContactUs />}/>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/france" element={<France />} />
        <Route path="/holandia" element={<Holandia />} />
        <Route path="/austria" element={<Austria />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
