import React from "react";

const Privacy = () => {
  return (
    <main className="privacy margin-bot-block">
      <section>
        <h1 className="h3-size">Polityka Prywatności grajowygrane.com</h1>
        <p className="t-16">
          Na grajowygrane.com, dostępnej pod adresem grajowygrane.com, jednym z
          naszych głównych priorytetów jest prywatność naszych odwiedzających.
          Ten dokument Polityki Prywatności zawiera rodzaje informacji, które są
          zbierane i rejestrowane przez grajowygrane.com oraz jak je
          wykorzystujemy.
          <br />
          Jeśli masz dodatkowe pytania lub potrzebujesz więcej informacji na
          temat naszej Polityki Prywatności, nie wahaj się z nami skontaktować.
        </p>
        <h2 className="h3-size">Pliki Logów</h2>
        <p className="t-16">
          grajowygrane.com stosuje standardową procedurę używania plików logów.
          Te pliki logują odwiedzających podczas wizyty na stronach
          internetowych. Wszystkie firmy hostingowe to robią i stanowi to część
          analityki usług hostingowych. Informacje zbierane przez pliki logów
          obejmują adresy IP (internet protocol), typ przeglądarki, dostawcę
          usług internetowych (ISP), datę i godzinę, strony odsyłające/wyjścia
          oraz ewentualnie liczbę kliknięć. Te informacje nie są powiązane z
          żadnymi danymi osobowymi, które można zidentyfikować. Celem tych
          informacji jest analiza trendów, zarządzanie stroną, śledzenie ruchu
          użytkowników na stronie oraz zbieranie informacji demograficznych.
          Nasza Polityka Prywatności została stworzona przy pomocy Generatora
          Polityki Prywatności.
        </p>
        <h2 className="h3-size">Cookies i Web Beacons</h2>
        <p className="t-16">
          Podobnie jak każda inna strona internetowa, grajowygrane.com używa
          "cookies". Te pliki cookie są używane do przechowywania informacji, w
          tym preferencji odwiedzających oraz stron na stronie internetowej,
          które odwiedzający otworzył lub odwiedził. Informacje te są
          wykorzystywane do optymalizacji doświadczeń użytkowników poprzez
          dostosowywanie treści naszej strony internetowej w oparciu o typ
          przeglądarki odwiedzających i/lub inne informacje. Więcej ogólnych
          informacji na temat plików cookie można znaleźć w artykule "Cookies" z
          Generatora Polityki Prywatności.
        </p>
        <h2 className="h3-size">Polityki Prywatności</h2>
        <p className="t-16">
          Możesz zapoznać się z tą listą, aby znaleźć Politykę Prywatności dla
          każdego z partnerów reklamowych grajowygrane.com.
          <br />
          Zewnętrzne serwery reklamowe lub sieci reklamowe używają technologii
          takich jak cookies, JavaScript lub Web Beacons, które są używane w ich
          odpowiednich reklamach i linkach, które pojawiają się na
          grajowygrane.com i są wysyłane bezpośrednio do przeglądarek
          użytkowników. Automatycznie otrzymują one twój adres IP, gdy to
          nastąpi. Technologie te są używane do pomiaru skuteczności ich
          kampanii reklamowych i/lub do personalizacji treści reklamowych, które
          widzisz na stronach internetowych, które odwiedzasz. <br />
          Należy pamiętać, że grajowygrane.com nie ma dostępu do tych plików
          cookie ani nad nimi kontroli, które są używane przez zewnętrznych
          reklamodawców.
        </p>
        <h2 className="h3-size">Polityki Prywatności Stron Trzecich</h2>
        <p className="t-16">
          Polityka Prywatności grajowygrane.com nie dotyczy innych reklamodawców
          ani stron internetowych. Dlatego doradzamy, abyś zapoznał się z
          odpowiednimi Politykami Prywatności tych zewnętrznych serwerów
          reklamowych, aby uzyskać bardziej szczegółowe informacje. Może to
          obejmować ich praktyki oraz instrukcje, jak zrezygnować z pewnych
          opcji.
          <br />
          Możesz wyłączyć pliki cookie za pomocą indywidualnych opcji
          przeglądarki. Aby uzyskać bardziej szczegółowe informacje na temat
          zarządzania plikami cookie w określonych przeglądarkach internetowych,
          można je znaleźć na odpowiednich stronach internetowych przeglądarek.
          Co to są pliki cookie?
        </p>
        <h2 className="h3-size">Informacje dla Dzieci</h2>
        <p className="t-16">
          Kolejną częścią naszego priorytetu jest dodanie ochrony dla dzieci
          korzystających z internetu. Zachęcamy rodziców i opiekunów do
          obserwowania, uczestniczenia i/lub monitorowania i kierowania ich
          aktywnością online.
          <br />
          grajowygrane.com nie zbiera świadomie żadnych danych osobowych
          umożliwiających identyfikację od dzieci poniżej 13 roku życia. Jeśli
          uważasz, że twoje dziecko przekazało tego rodzaju informacje na naszej
          stronie internetowej, gorąco zachęcamy do natychmiastowego kontaktu z
          nami, a dołożymy wszelkich starań, aby szybko usunąć takie informacje
          z naszych rejestrów.
        </p>
        <h2 className="h3-size">Polityka Prywatności Online Tylko</h2>
        <p className="t-16">
          Niniejsza Polityka Prywatności dotyczy tylko naszych działań online i
          jest ważna dla odwiedzających naszą stronę internetową w odniesieniu
          do informacji, które udostępnili i/lub które zbieramy na
          grajowygrane.com. Ta polityka nie ma zastosowania do żadnych
          informacji zebranych offline lub za pośrednictwem kanałów innych niż
          ta strona internetowa.
        </p>
        <h2 className="h3-size">Zgoda</h2>
        <p className="t-16">
          Korzystając z naszej strony internetowej, zgadzasz się na naszą
          Politykę Prywatności i akceptujesz jej Warunki.
        </p>
      </section>
    </main>
  );
};

export default Privacy;
