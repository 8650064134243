import React from "react";
import "./DirectionPages.css";
import holandiaMain from "../../images/holandia-cover.webp";
import holandiaMainMob from "../../images/holandia-cover-mob.webp";
import hotOne from "../../images/Van-der-Valk-Hotel-Tilburg.webp";
import hotTwo from "../../images/Van-der-Valk-Hotel-Sassenheim-Leiden.webp";
import hotThree from "../../images/Hotel-Lowietje-Lisse-Keukenhof.webp";

import pin from "../../images/pin.svg";
import stars from "../../images/4-stars.svg";
import checkIn from "../../images/check-in.svg";
import checkOut from "../../images/check-out.svg";
import arrow from "../../images/arrow.svg";

const Holandia = () => {
  return (
    <main className="direction-page">
      <section className="cover">
        <img
          className="cover__img pc"
          src={holandiaMain}
          alt="main cover"
          width="1440"
          height="600"
        />
        <img
          className="cover__img mob"
          src={holandiaMainMob}
          alt="main cover"
          width="375"
          height="560"
        />
        <div className="cover__overlay">
          <h1 className="col-white cover__header">Holandia</h1>
          <p className="h3-size col-white cover__text">
            Amsterdam słynie z malowniczych kanałów, zabytkowych domów i
            światowej klasy muzeów.
          </p>
        </div>
      </section>
      <section className="hotels direction__pages">
        <h2 className="hotels__header">Nasze najlepsze rekomendacje hoteli</h2>
        <p className="t-24 col-grey hotels__text">
          Holandia to kraj malowniczych kanałów, pól kwiatowych i bogatego
          dziedzictwa kulturowego. Nasze biuro podróży wybrało najlepsze hotele,
          abyś mógł cieszyć się autentyczną holenderską gościnnością i
          komfortem. Od stylowych hoteli miejskich w Amsterdamie po przytulne
          wiejskie domy, mamy idealne miejsce na niezapomniane wakacje.
        </p>
        <ul className="hotels__container">
          <li className="hotel hotel-one">
            <div className="hotel__text-container">
              <p className="h3-size hotel__name">Van der Valk Hotel Tilburg</p>
              <div className="hotel__rating-container">
                <img
                  src={stars}
                  alt="star icon"
                  title="star icon"
                  className="hotel__star"
                />
                <p className="t-16 col-grey rating">266 recenzji</p>
              </div>
              <div className="hotel__adress-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="24"
                  height="24"
                />
                <p className="t-16 col-grey">
                  Dr. Bloemenlaan 8, 5022 KW, Tilburg, 5022
                </p>
              </div>
              <p className="hotel__about t-16 col-grey">
                Van der Valk Hotel Tilburg oferuje kasyno, taras i ogród. Hotel
                oferuje 109 automatów do gry, dzięki którym można wygrać główną
                wygraną. W wolnej chwili Goście mogą zrelaksować się w saunie
                lub łaźni parowej.
              </p>
              <p className="hotel__lang t-16">Języki:</p>
              <p className="t-16 col-grey">Dutch, English, German</p>
              <div className="hotel__check">
                <div className="hotel__check-in">
                  <img
                    src={checkIn}
                    alt="check-in icon"
                    title="check-in icon"
                    width="30"
                    height="30"
                  />
                  <p className="t-16">Czas zameldowania</p>
                  <p className="t-16 col-grey">3:00 PM</p>
                </div>
                <div className="hotel__check-out">
                  <img
                    src={checkOut}
                    alt="check-out icon"
                    title="check-out icon"
                    width="30"
                    height="30"
                  />
                  <p className="t-16">Czas wymeldowania</p>
                  <p className="t-16 col-grey">11:00 PM</p>
                </div>
              </div>
              <a
                className="hotel__reserve t-16"
                target="_blank"
                rel="noreferrer"
                href="https://www.expedia.com/Tilburg-Hotels-Van-Der-Valk-Hotel-Tilburg.h22226416.Hotel-Information?chkin=2024-07-14&chkout=2024-07-16&x_pwa=1&rfrr=HSR&pwa_ts=1720934916898&referrerUrl=aHR0cHM6Ly93d3cuZXhwZWRpYS5jb20vSG90ZWwtU2VhcmNo&useRewards=false&rm1=a2&regionId=129&destination=Netherlands&destType=MARKET&latLong=52.132633%2C5.291266&amenities=CASINO&sort=RECOMMENDED&top_dp=167&top_cur=USD&userIntent=&selectedRoomType=216004181&selectedRatePlan=260015346&searchId=165fa5df-3701-48a0-9e70-acc28bdaee78&propertyName=Van+der+Valk+Hotel+Tilburg"
              >
                Rezerwacja hotelu
                <img
                  src={arrow}
                  alt="arrow icon"
                  title="arrow icon"
                  className="hotel__arrow"
                />
              </a>
            </div>
            <div className="hotel__img-container">
              <img
                src={hotOne}
                alt="Van der Valk Hotel Tilburg"
                title="Van der Valk Hotel Tilburg"
                width="417"
                height="490"
                className="hotel__img"
              />
            </div>
          </li>

          <li className="hotel hotel-two">
            <div className="hotel__text-container">
              <p className="h3-size hotel__name">
                Van der Valk Hotel Sassenheim - Leiden
              </p>
              <div className="hotel__rating-container">
                <img
                  src={stars}
                  alt="star icon"
                  title="star icon"
                  className="hotel__star"
                />
                <p className="t-16 col-grey rating">428 recenzji</p>
              </div>
              <div className="hotel__adress-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="24"
                  height="24"
                />
                <p className="t-16 col-grey">
                  Warmonderweg 8, Sassenheim, 2171 AH
                </p>
              </div>
              <p className="hotel__about t-16 col-grey">
                Hotel Van der Valk Sassenheim - Leiden położony jest w pobliżu
                klubu golfowego Kagerzoom i oferuje kasyno, taras oraz usługi
                pralni chemicznej. Dla tych, którzy chcą spróbować szczęścia,
                hotel oferuje 250 automatów do gry. Goście mogą zrelaksować się
                w saunie. Goście mogą zjeść posiłek w jednej z 3 restauracji na
                terenie obiektu.
              </p>
              <p className="hotel__lang t-16">Języki:</p>
              <p className="t-16 col-grey">Dutch, English, German</p>
              <div className="hotel__check">
                <div className="hotel__check-in">
                  <img
                    src={checkIn}
                    alt="check-in icon"
                    title="check-in icon"
                    width="30"
                    height="30"
                  />
                  <p className="t-16">Czas zameldowania</p>
                  <p className="t-16 col-grey">3:00 PM</p>
                </div>
                <div className="hotel__check-out">
                  <img
                    src={checkOut}
                    alt="check-out icon"
                    title="check-out icon"
                    width="30"
                    height="30"
                  />
                  <p className="t-16">Czas wymeldowania</p>
                  <p className="t-16 col-grey">12:00 PM</p>
                </div>
              </div>
              <a
                className="hotel__reserve t-16"
                target="_blank"
                rel="noreferrer"
                href="https://www.expedia.com/The-Hague-Hotels-Van-Der-Valk-Hotel-Sassenheim-Leiden.h1088376.Hotel-Information?chkin=2024-07-14&chkout=2024-07-16&x_pwa=1&rfrr=HSR&pwa_ts=1720944521823&referrerUrl=aHR0cHM6Ly93d3cuZXhwZWRpYS5jb20vSG90ZWwtU2VhcmNo&useRewards=false&rm1=a2&regionId=129&destination=Netherlands&destType=MARKET&latLong=52.132633%2C5.291266&amenities=CASINO&sort=RECOMMENDED&top_dp=115&top_cur=USD&userIntent=&selectedRoomType=200575676&selectedRatePlan=202904771&searchId=9767e9bd-5b05-48bf-bee5-1505003d040f&propertyName=Van+der+Valk+Hotel+Sassenheim+-+Leiden"
              >
                Rezerwacja hotelu
                <img
                  src={arrow}
                  alt="arrow icon"
                  title="arrow icon"
                  className="hotel__arrow"
                />
              </a>
            </div>
            <div className="hotel__img-container">
              <img
                src={hotTwo}
                alt="Van der Valk Hotel Sassenheim - Leiden"
                title="Van der Valk Hotel Sassenheim - Leiden"
                width="417"
                height="490"
                className="hotel__img"
              />
            </div>
          </li>

          <li className="hotel hotel-three">
            <div className="hotel__text-container">
              <p className="h3-size hotel__name">Hotel Lowietje Lisse - Keukenhof</p>
              <div className="hotel__rating-container">
                <img
                  src={stars}
                  alt="star icon"
                  title="star icon"
                  className="hotel__star"
                />
                <p className="t-16 col-grey rating">27 recenzji</p>
              </div>
              <div className="hotel__adress-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="24"
                  height="24"
                />
                <p className="t-16 col-grey">
                  Heereweg 10, Lisse, South Holland, 2061 AD
                </p>
              </div>
              <p className="hotel__about t-16 col-grey">
                Kasyno, kawiarnia i bar to tylko niektóre z udogodnień
                oferowanych przez obiekt Hotel Lowietje Lisse - Keukenhof. Dla
                tych, którzy chcą spróbować szczęścia, hotel oferuje 10
                automatów do gry, pokój VIP w kasynie i 10 stołów do gry w
                kasynie. Hotelowa restauracja serwująca dania kuchni
                azjatyckiej, Restaurant Hanami Lisse, zaprasza na lunch i
                kolację.
              </p>
              <p className="hotel__lang t-16">Języki:</p>
              <p className="t-16 col-grey">Dutch, English, French, German</p>
              <div className="hotel__check">
                <div className="hotel__check-in">
                  <img
                    src={checkIn}
                    alt="check-in icon"
                    title="check-in icon"
                    width="30"
                    height="30"
                  />
                  <p className="t-16">Czas zameldowania</p>
                  <p className="t-16 col-grey">2:00 PM</p>
                </div>
                <div className="hotel__check-out">
                  <img
                    src={checkOut}
                    alt="check-out icon"
                    title="check-out icon"
                    width="30"
                    height="30"
                  />
                  <p className="t-16">Czas wymeldowania</p>
                  <p className="t-16 col-grey">11:00 PM</p>
                </div>
              </div>
              <a
                className="hotel__reserve t-16"
                target="_blank"
                rel="noreferrer"
                href="https://www.expedia.com/Bloemendaal-Hotels-Hotel-Lowietje-Lisse-Keukenhof.h103861883.Hotel-Information?chkin=2024-07-14&chkout=2024-07-16&x_pwa=1&rfrr=HSR&pwa_ts=1720944521823&referrerUrl=aHR0cHM6Ly93d3cuZXhwZWRpYS5jb20vSG90ZWwtU2VhcmNo&useRewards=false&rm1=a2&regionId=129&destination=Netherlands&destType=MARKET&latLong=52.132633%2C5.291266&amenities=CASINO&sort=RECOMMENDED&top_dp=88&top_cur=USD&userIntent=&selectedRoomType=324513589&selectedRatePlan=394884487&searchId=9767e9bd-5b05-48bf-bee5-1505003d040f&propertyName=Hotel+Lowietje+Lisse+-+Keukenhof"
              >
                Rezerwacja hotelu
                <img
                  src={arrow}
                  alt="arrow icon"
                  title="arrow icon"
                  className="hotel__arrow"
                />
              </a>
            </div>
            <div className="hotel__img-container">
              <img
                src={hotThree}
                alt="Hotel Lowietje Lisse - Keukenhof"
                title="Hotel Lowietje Lisse - Keukenhof"
                width="417"
                height="490"
                className="hotel__img"
              />
            </div>
          </li>
        </ul>
      </section>
    </main>
  );
};

export default Holandia;
