import React, { useState } from "react";
import axios from "axios";
import "./ContactUs.css";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('mail.php', formData)
      .then(response => {
        console.log('Message send');
      })
      .catch(error => {
        console.log('Error sending message');
      });
  };

  return (
    <section className="contacts">
      <h1 className="h2-size contacts__header">Zostaw swój ślad!</h1>
      <p className="contacts__text">
      Wypełnij formularz, a my skontaktujemy się z Tobą!
      </p>
      <form onSubmit={handleSubmit} className="contacts__form">
        <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Imię..."
            className="form__input"
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="E-mail..."
            className="form__input"
          />
          <button type="submit" className="form__btn">
          Wysłać
          </button>
      </form>
    </section>
  );
};

export default ContactUs;
