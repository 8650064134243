import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import burgerMenu from "../../images/burger-menu.svg";
import closeBtn from "../../images/close-btn.svg";
import { useState } from "react";

function Header({ open }) {
  const [isActive, setIsActive] = useState(false);
  const navigation = useNavigate();

  const handleLinkClick = () => {
    setIsActive(false);
  };

  const handleNavigation = (e, path, hash) => {
    e.preventDefault();
    navigation(path);
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      });
    }
  };

  return (
    <div className="header__container">
      <div className="header__link-container">
        <Link to="/" className="header__link">
          Strona główna
        </Link>
        <p
          className="header__link"
          onClick={() => {
            open();
          }}
        >
          Kierunki
        </p>
        <Link to="/hot-deals" className="header__link">
          Gorące oferty
        </Link>
        <Link to="/reviews" className="header__link">
          Recenzje
        </Link>
        <Link to="/contact-us" className="header__link">
          Kontakty
        </Link>
      </div>

      <div className="burger-menu">
        <div id="burger-menu">
          <img
            src={burgerMenu}
            alt="burger menu icon"
            className="burger__icon"
            onClick={() => setIsActive(true)}
          />
        </div>
        <nav
          className={`burger-menu__nav ${isActive ? "active" : ""}`}
          id="nav"
        >
          <img
            src={closeBtn}
            className="burger__close-btn"
            id="close-icon"
            alt="close menu icon"
            onClick={() => setIsActive(false)}
          />
          <ul className="burger-menu__nav-container">
            <li className="burger-menu__nav-link">
              <Link to="/" className="header__link" onClick={handleLinkClick}>
                Strona główna
              </Link>
            </li>
            <li className="burger-menu__nav-link">
              <Link
                to="/directions"
                className="header__link"
                onClick={handleLinkClick}
              >
                Kierunki
              </Link>
            </li>
            <li className="burger-menu__nav-link">
              <Link
                to="/hot-deals"
                className="header__link"
                onClick={handleLinkClick}
              >
                Gorące oferty
              </Link>
            </li>
            <li className="burger-menu__nav-link">
              <Link
                to="/reviews"
                className="header__link"
                onClick={handleLinkClick}
              >
                Recenzje
              </Link>
            </li>
            <li className="burger-menu__nav-link">
              <Link to="/contact-us" className="header__link" onClick={handleLinkClick}>
                Kontakty
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;
