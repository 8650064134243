import React from "react";
import "./Main.css";
import Popup from "../Popup/Popup";
import Directions from "../Directions/Directions";
import BestHotels from "../HotDeals/HotDeals";
import Reviews from "../Reviews/Reviews";
import mainImg from "../../images/main-cover.webp";
import mainImgMob from "../../images/main-cover-mob.webp";
import france from "../../images/direction-france.webp";
import holandia from "../../images/direction-holandia.webp";
import austria from "../../images/direction-austria.webp";
import { Link } from "react-router-dom";

const MainCover = () => {
  return (
    <main className="main">
      {/* <Popup/> */}
      <section className="cover">
        <img
          className="cover__img pc"
          src={mainImg}
          alt="main cover"
          width="1440"
          height="600"
          loading="lazy"
        />
        <img
          className="cover__img mob"
          src={mainImgMob}
          alt="main cover"
          width="375"
          height="560"
        />
        <div className="cover__overlay">
          <h1 className="col-white cover__header">
            Odkryj świat dzięki <br></br>gorącym ofertom!
          </h1>
          <p className="h3-size col-white cover__text">
            Zebraliśmy najlepsze i <br></br>najbezpieczniejsze hotele.
          </p>
        </div>
      </section>

      <section className="directions">
        <div className="directions__text">
          <h2 className="directions__header">Wybierz kraj</h2>
          <p className="directions__paragraph t-24 col-grey">
            Planujesz niezapomnianą podróż do Europy? Jesteśmy gotowi pomóc Ci w
            wyborze! Europa oferuje wiele różnych miejsc, z których każde jest
            wyjątkowe i pełne niesamowitych miejsc, kultury i historii. Dowiedz
            się więcej o najlepszych hotelach, atrakcjach i miejscach w tych
            wspaniałych miastach.
          </p>
        </div>

        <div className="directions__link-container">
          <Link to="/france" className="directions__link">
            <img
              src={france}
              alt="link to page about france"
              title="link to page about france"
              width="281"
              height="189"
              className="directions__img"
            />
          </Link>
          <Link to="/holandia" className="directions__link">
            <img
              src={holandia}
              alt="link to page about holandia"
              title="link to page about holandia"
              width="281"
              height="189"
              className="directions__img"
            />
          </Link>
          <Link to="/austria" className="directions__link">
            <img
              src={austria}
              alt="link to page about austria"
              title="link to page about austria"
              width="281"
              height="189"
              className="directions__img"
            />
          </Link>
        </div>
      </section>

      <BestHotels />
      <Reviews />
    </main>
  );
};

export default MainCover;
